.site-configuration {
    .form-switch {
        margin-top: calc(0.4375rem + 1px);;
    }

    .btn-sm {
        margin-top: calc(0.2375rem + 1px);
    }

    .form-check-input {
        width: 2.5rem;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    .list-group-transparent .list-group-item {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &:hover {
            background: rgba(32, 107, 196, 0.04);
        }
    }

    .transactions .form-check-input {
        float: unset !important;
    }

    .captchas small {
        line-height: 1.125rem;
        padding-left: 0.5rem;
    }
}